<template>
  <div id="app">
    <div class="login">
      <van-form @submit="onSubmit" ref="loginForm" class="login-form">
        <img src="~@/assets/logo1.png" class="img1" alt="">
        <img src="~@/assets/logo2.png" class="img2" alt="">
        <van-field v-model="data.username" name="账号" label="账号" placeholder="账号" :rules="[{ required: true, message: '账号' }]" ref="username" />
        <van-field v-model="data.password" type="password" name="密码" label="密码" placeholder="密码" :rules="[{ required: true, message: '请填写密码' }]" ref="password" />
        <div class="submit">
          <div class="submit-button">
            <van-button round block type="primary" native-type="submit">提交</van-button>
          </div>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {
        username: '',
        password: ''
      }
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    }
  },
  mounted() {
    if (this.data.username === '') {
      this.$refs.username.focus()
    } else if (this.data.password === '') {
      this.$refs.password.focus()
    }

    const storageUsername = localStorage.getItem('username')
    const storagePassword = localStorage.getItem('password')
    if (storageUsername && storagePassword) {
      this.data.username = storageUsername
      this.data.password = storagePassword
    }
  },
  methods: {
    onSubmit() {
      this.$store.dispatch('user/login', this.data).then(() => {
        const username = this.data.username
        const password = this.data.password
        localStorage.setItem('username', username)
        localStorage.setItem('password', password)
        this.$router.push({ path: this.redirect || '/index', query: this.otherQuery })
        this.loading = false
      })
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    }
  }
}
</script>
<style scoped>
.login{
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 999;
  background-image: url("~@/assets/login-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: 60px;
}
.login-form{
  width: 80%;
  margin:0 auto;
  padding:60px 10px;
  background-color: rgba(255, 255, 255, .6);
}
.login-form .van-cell{
  margin-bottom: 26px;
}
.login-form .img1{
  width: 200px;
}
.login-form .img2{
  width: 220px;
  margin-bottom: 20px;
}
</style>
